.contact-us .boxes {
  display: flex;
  justify-content: center;
  width: 100%;
}

.contact-us .box {
  border: 3px solid black;
  border-radius: 5px;
}

@media screen and (max-width: 686px) {
    .contact-us .boxes {
        flex-wrap: wrap;
    }
}
