a {
  text-decoration: none;
  color: inherit;
}

.text-center {
  text-align: center !important;
}

.content p {
  line-height: 2rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  text-align: justify;
}

.apply-here .content p {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.content h4,
.content h5,
.content h6 {
  text-align: center;
  margin-bottom: 10px;
}

.center-main {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
}
