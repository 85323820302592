@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mouse+Memoirs&display=swap");

:root {
  color-scheme: only light;
}
div.MuiPickersCalendarHeader-labelContainer > div{
  font-size: 1rem;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.roboto {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px !important;
}

.mouse-memoirs {
  font-family: "Mouse Memoirs", "Roboto", sans-serif !important;
  font-size: 28px !important;
  letter-spacing: 2px !important;
}

.title-color {
  color: #e54f19;
  font: inherit;
}

.fs-28 {
  font-size: 28px !important;
}

:root {
  --swiper-theme-color: rgb(234, 90, 38) !important;
}

#root {
  margin: 0;
  padding: 0;
  position: relative; /* Establishes a positioning context for ::before */
}

.main-background-image {
  position: relative;
}

.main-background-image::before {
  content: "";
  background-image: url("/public/assets/template.jpg");
  background-size: cover;
  background-position: center;
  position: fixed; /* Fixed to cover the entire viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* Places it behind the body content */
  opacity: 0.5; /* Adjust the opacity as needed */
}

#root > div {
  position: relative;
}

.menu-back {
  background-color: black;
  opacity: 60%;
}

.image-container {
  position: relative;
}

.image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.f45 {
  font-size: 4.5vw;
}

.f35 {
  font-size: 3.5vw;
}

.f6 {
  font-size: 7vw;
  /* color: #FFAA1D; */
}

.mapouter {
  position: relative;
  text-align: right;
  height: 560px;
  width: calc(100vw - 100px);
  max-width: 820px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 560px;
  width: calc(100vw - 100px);
  max-width: 820px;
}

@media screen and (max-width: 768px) {
  .f45 {
    font-size: 25px;
  }

  .f35 {
    font-size: 3.5vw;
  }

  .f6 {
    font-size: 50px;
    /* color: #FFAA1D; */
  }
}
