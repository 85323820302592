.custom__cards{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1vw;
    place-items: center;
}

@media screen and (max-width: 970px) {
    .custom__cards{
        grid-template-columns: 1fr;
    }
}