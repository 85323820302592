.home__swipper {
    height: 100vh;
}

.home__swipper .swiper {
    width: 100%;
    height: 100%;
}

.home__swipper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.home__swipper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}